.blog {
    padding-bottom: 140px;
    overflow: hidden;
}

.container {
    /* max-width: 1280px; */
    padding: 0 4rem;
    margin: 0 auto;
}

.title {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    margin-bottom: 2.5rem;
}

.blockPosts {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 1rem;
    margin-bottom: 2.5rem;
}

.namePost {
    margin-top: 1.5rem;
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #ffffff;
}

.aboutPost {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    margin-top: 0.25rem;
}

.postBtn {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgba(29, 30, 41, 1);
    padding: 0.5rem 1rem;
    background-color: rgba(215, 252, 81, 1);
    cursor: pointer;
    transition: all .15s ease;
}

.postBtn:hover,
.postBtn:active {
    background: #ADD718;
    color: #1D1E29;
}

.imgPost img {
    width: 100%;
    height: 191px !important;
    overflow: hidden;
    object-fit: cover;
}

.imgPost img {
    border-radius: 1rem;
}

.blockBtn {
    display: flex;
    justify-content: center;
}

@media (min-width: 2000px) {
    .container {
        max-width: 80%;
    }
    .imgPost img {
        width: 100%;
        height: 280px !important;
        overflow: hidden;
        object-fit: cover;
    }
  }

@media (min-width: 1440px) and (max-width: 1920px) {
    .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1200px) {
    .blockPosts {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 1rem;
        grid-row-gap: 2.25rem;
    }
}

@media only screen and (max-width: 768px) {
    .blog {
        padding-bottom: 108px;
    }

    .container {
        padding: 0 1rem;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 2rem;
    }

    .imgPost span {
        height: 191px !important;
    }

    .blockPosts {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-column-gap: 0;
    }

    .namePost {
        margin-top: 1rem;
        font-size: 1.375rem;
    }

    .aboutPost {
        margin-top: 0;
    }
}
